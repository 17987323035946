<script>
import Swal from "sweetalert2";

import Layout from "../../../layouts/main.vue";
import axios from "axios";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      name: {
        val: "",
        isValid: true,
      },
      description: {
        val: "",
        isValid: true,
      },
      medicine: [],
      id: "",
      formIsValid: true,
    };
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true
    },
    reloadPage() {
      window.location.reload();
    },
    validateForm() {
      if (this.name.val === "") {
        this.name.isValid = false;
        this.formIsValid = false;
      }
      if (this.description.val === "") {
        this.description.isValid = false;
        this.formIsValid = false;
      }
      else {
        this.formIsValid = true;
      }
    },
    async getMedicine() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/medicine/"
      );
      this.medicine = response.data;
      const lastEl = this.medicine[this.medicine.length - 1];
      this.id = lastEl.id;
      this.successmsg();
    },

    async addMedicine() {
      this.validateForm();
      if (!this.formIsValid) {
        this.errormsg();
        return;
      } else {
        var formdata = new FormData();
        formdata.append("name", this.name.val);
        formdata.append("description", this.description.val);

        const result = await axios.post(
          "https://api.doctosoft.com/index.php/api/medicine",
          formdata
        );
        this.msg = result.data;
        this.getMedicine();
        this.$router.push("/admin/medicine");
      }
    },

    errormsg() {
      Swal.fire(
        "Invalid Form",
        "Please fix the errors and try again later",
        "error"
      );
    },
    successmsg() {
      Swal.fire(
        "Good job!",
        "Created new medicine successfully " ,
        "success"
      );
    },
  },
};
</script>
<template>
  <Layout>
    <div class="back-btn">
      <div class="arrow">
        <button class="btn btn-light" @click="$router.push('/admin/medicine')"><i
            class="mdi mdi-chevron-left"></i></button>
      </div>
      <div class="action-btn">
        <b-button class="btn-soft-info waves-effect waves-light"
          @click="$router.push('/admin/medicine')">Cancel</b-button>
        <button class="btn  btn-md btn btn-primary" type="submit" @click="addMedicine">Save</button>
      </div>
    </div>
    <b-row class="card-main">
      <b-col sm="6" xl="10">
        <b-card no-body>
          <form class="needs-validation" @submit.prevent="addMedicine">
            <div class="mb-3" :class="{ invalid: !name.isValid }">
              <label for="name" class="form-label">Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="name.val" placeholder="Enter name"
                @blur="clearValidity('name')" />
            </div>

            <div class="mb-3" :class="{ invalid: !description.isValid }">
              <label for="description" class="form-label">Description <span class="text-danger">*</span></label>
              <textarea rows="10" class="form-control" v-model="description.val" placeholder="Enter Description"
                @blur="clearValidity('description')" />
            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>
